import {
  Container,
  TablePagination,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Box,
  ButtonProps,
  Stack,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { ChangeEvent, ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { ROWS_PER_PAGE } from 'services/api-constants';
import dayjs from 'services/dayjs';
import { getPrefixedRoute } from 'services/theming';
import Button from 'components/Button';
import ChartContainer from 'components/ChartContainer';
import CutoffProgressBar, { LabelIconVariant } from 'components/CutoffProgressBar';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import ExpandableTableNew from 'components/ExpandableTableNew/ExpandableTableNew';
import { ExpandableTablePlaceholder as ExpandableTablePlaceholderNew } from 'components/ExpandableTableNew/ExpandableTablePlaceholder';
import Hero from 'components/Hero';
import { SearchAction } from 'components/SearchBar';
import WeekSelector from 'components/WeekSelector';
import { useWhiteLabelling } from 'components/WhiteLabellingProvider';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import useNumberParams from 'hooks/useNumberParams';
import useSearch from 'hooks/useSearch';
import useSyncedState from 'hooks/useSyncedState';
import useUser from 'hooks/useUser';
import { updateExpandableTableFields } from 'utils/table';
import TruckIconVilla from 'images/truck-progress-bar-villa.svg';
import TruckIcon from 'images/truck-progress-bar.svg';
import {
  CardIntent,
  ExpandableTable as ExpandableTableType,
  TablesScreen as TablesScreenType,
  TablesScreenInput,
  ChartData,
} from 'generated/graphql';
import { ConfirmDialogVariant, FormDialogState } from '../components/FormDialog';
import LazyFormDialog from '../components/LazyFormDialog';
import { defaultFormDialogState } from '../components/default-form-dialog-state';
import { EmptyTableState, EmptyTableVariant } from './EmptyTableState';

const toTablesScreenInput = (screen?: TablesScreenType): TablesScreenInput => ({
  search: '',
  tabs:
    screen?.tabs.map((t, tIndex) => ({
      tabIndex: tIndex,
      tabGroups: t.tableGroups.map((g, gIndex) => ({
        groupIndex: gIndex,
        tablePageInfos: g.tables.map((tbl) => ({
          identifier: tbl.title.trim().toLowerCase(),
          pageNumber: tbl.pageInfo.page,
        })),
      })),
    })) ?? [],
});

interface TableScreenInputMinified {
  s: string;
  t: {
    i: number;
    g: {
      i: number;
      p: {
        i: string;
        n: number;
      }[];
    }[];
  }[];
}

const minifyInput = (input: TablesScreenInput): TableScreenInputMinified => ({
  s: input.search,
  t: input.tabs.map((t) => ({
    i: t.tabIndex,
    g: t.tabGroups.map((g) => ({
      i: g.groupIndex,
      p: g.tablePageInfos.map((tbl) => ({
        i: tbl.identifier,
        n: tbl.pageNumber,
      })),
    })),
  })),
});

const unminifyInput = (minified: TableScreenInputMinified): TablesScreenInput => ({
  search: minified.s,
  tabs: minified.t.map((t) => ({
    tabIndex: t.i,
    tabGroups: t.g.map((g) => ({
      groupIndex: g.i,
      tablePageInfos: g.p.map((tbl) => ({
        identifier: tbl.i,
        pageNumber: tbl.n,
      })),
    })),
  })),
});

export const tableScreenInputToString = (input: TablesScreenInput | null): string => {
  try {
    if (!input) return '';

    const minified = btoa(JSON.stringify(minifyInput(input)));

    return minified;
  } catch {
    return '';
  }
};

export const tableScreenInputFromString = (json: string): TablesScreenInput | null => {
  try {
    const parsed = JSON.parse(atob(json));

    if (!parsed) {
      return null;
    }

    return unminifyInput(parsed);
  } catch {
    return null;
  }
};

const buildLatestInput = (
  latestInput: TablesScreenInput | undefined,
  currentScreen?: TablesScreenType,
): TablesScreenInput => {
  const fromScreen = toTablesScreenInput(currentScreen);
  if (latestInput && !latestInput.tabs.length) {
    return {
      ...latestInput,
      tabs: fromScreen.tabs,
    };
  }

  return latestInput || fromScreen;
};

export enum TableScreenQueryParam {
  Tab = 't',
  Group = 'g',
  Input = 'i',
  Search = 's',
}

const ONE_MINUTE = 60 * 1000;

const getCurrentTime = () => dayjs().hour() * 3600 + dayjs().minute() * 60;

const TableWrapper = ({
  table,
  tables,
  isRevalidating,
  screen,
  index,
  tabIndex,
  tableGroupIndex,
  currentInput,
  onRefresh,
  onTableActionClick,
  onCustomSchemeRequest,
  onScreenUpdate,
  onInputUpdate,
  onNewPageSelect,
}: {
  table: ExpandableTableType;
  tables: ExpandableTableType[];
  screen?: TablesScreenType;
  isRevalidating: boolean;
  index: number;
  tabIndex: number;
  tableGroupIndex: number;
  currentInput?: TablesScreenInput;

  onRefresh: () => void;
  onTableActionClick: (code: string, rowIds: string[]) => void;
  onCustomSchemeRequest: (url: string) => void;
  onScreenUpdate: (screen: TablesScreenType) => void;
  onInputUpdate: (input: TablesScreenInput) => void;
  onNewPageSelect: (page: number, tableTitle: string) => void;
}) => {
  const containerRef = useRef<ElementRef<'div'>>(null);
  const isEmptyTable = table.groups.every((group) => group.rows.length === 0) && table.tableActions.length === 0;

  if (isEmptyTable) return null;

  return (
    <Box
      ref={containerRef}
      sx={(theme) => ({
        marginTop: theme.spacing(1),
      })}
    >
      <ExpandableTableNew
        table={table}
        showTitle
        isFetching={isRevalidating}
        onRefresh={onRefresh}
        onActionClick={onTableActionClick}
        onCustomSchemeRequest={onCustomSchemeRequest}
        onFieldUpdate={(rowId, cellIndex, newValue) => {
          const updatedTable = updateExpandableTableFields(table, cellIndex, rowId, newValue);
          if (screen && onScreenUpdate) {
            onScreenUpdate({
              ...screen,
              tabs: screen.tabs.map((tab, ti) => ({
                ...tab,
                tableGroups: tab.tableGroups.map((group, gi) => ({
                  ...group,
                  tables: group.tables.map((t, tableIndex) =>
                    tabIndex === ti && tableGroupIndex === gi && tableIndex === index ? updatedTable : t,
                  ),
                })),
              })),
            });
          }
        }}
        footer={
          Boolean(table.pageInfo) &&
          Boolean(onInputUpdate) && (
            <TablePagination
              component="td"
              count={-1}
              slotProps={{
                actions: {
                  nextButton: {
                    disabled: !table.pageInfo.hasNextPage,
                  },
                },
              }}
              labelDisplayedRows={({ from, to }) => `Page ${table.pageInfo.page + 1} (${from}-${to})`}
              page={table.pageInfo.page}
              labelRowsPerPage="Show"
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              onPageChange={(_, page) => onNewPageSelect(page, table.title)}
              ActionsComponent={
                currentInput &&
                !!currentInput.tabs.length &&
                currentInput?.tabs[tabIndex]?.tabGroups[tableGroupIndex]?.tablePageInfos.find(
                  (t) => t.identifier === table.title.trim().toLowerCase(),
                )?.pageNumber !== table.pageInfo.page
                  ? () => (
                      <CircularProgress
                        size={24}
                        color="secondary"
                        sx={(theme) => ({
                          display: 'flex',
                          margin: theme.spacing(1, 2),
                        })}
                      />
                    )
                  : undefined
              }
            />
          )
        }
      />
      {/* )} */}
    </Box>
  );
};

const TablesScreen = ({
  screen,
  isFetching = false,
  onInputUpdate = () => {},
  isRevalidating = false,
  currentInput,
  enableTabs = true,
  dateRange,
  onRefresh,
  onTableActionClick = () => {},
  onScreenUpdate = () => {},
  onCustomSchemeRequest = () => {},
}: {
  screen?: TablesScreenType;
  isFetching?: boolean;
  isRevalidating?: boolean;
  currentInput?: TablesScreenInput;
  enableTabs?: boolean;
  onRefresh(): void;
  onTableActionClick?(code: string, rowIds: string[]): void;
  onScreenUpdate?(screen: TablesScreenType): void;
  onInputUpdate?(input: TablesScreenInput): void;
  onCustomSchemeRequest?(url: string): void;
  dateRange?: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
    onDatesChange: (startDate: Dayjs, endDate: Dayjs) => void;
    label?: string;
    onReset: () => void;
  };
}) => {
  const { isEnabled } = useFeatures();
  const isSellerOrdersEnabled = isEnabled('SellerOrders');

  const isShipments = useMatch('/shipments');
  const [getNumberParam, setNumberParam] = useNumberParams();
  const [getParam] = useSearch();
  const searchValue = getParam(TableScreenQueryParam.Search) ?? '';
  const initialSearch = searchValue ?? currentInput?.search ?? '';
  const [internalInput, setInternalInput] = useState<TablesScreenInput | undefined>({
    ...currentInput,
    tabs: currentInput?.tabs ?? [],
    search: initialSearch,
  });
  const [internalScreen] = useSyncedState<TablesScreenType | undefined>(screen);

  const [search, setSearch] = useState<string>(initialSearch);
  const debouncedInput = useDebouncedValue(internalInput, 750);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const tabIndex = getNumberParam(TableScreenQueryParam.Tab) ?? 0;
  const tableGroupIndex = getNumberParam(TableScreenQueryParam.Group) ?? 0;

  const navigate = useNavigate();

  const { isSeller } = useUser();

  useEffect(() => {
    if (!debouncedInput || !onInputUpdate) {
      return;
    }
    onInputUpdate(debouncedInput);
  }, [debouncedInput, onInputUpdate]);

  const handleTabChange = (_: ChangeEvent<{}>, newTab: number) => {
    const screenTab = screen?.tabs[newTab];

    if (screenTab?.url && screenTab.url.length > 0) {
      const url = new URL(screenTab.url, window.location.origin);
      const params = new URLSearchParams(url.search);
      params.set(TableScreenQueryParam.Search, search);
      const profileID = getParam('profileID');
      if (profileID) {
        params.set('profileID', profileID);
      }
      url.search = params.toString();

      navigate(`${url.pathname}${url.search}`.trim(), { replace: true });
      return;
    }
    setNumberParam(TableScreenQueryParam.Tab, newTab);
    setNumberParam(TableScreenQueryParam.Group, 0);
  };

  const handleNewPageSelect = (page: number, tableTitle: string) => {
    if (!onInputUpdate) {
      return;
    }

    const prevInput = buildLatestInput(currentInput, screen);
    const tableID = tableTitle.trim().toLowerCase();

    const newInput: TablesScreenInput = {
      ...prevInput,
      tabs: prevInput.tabs.map((t, tIndex) =>
        tIndex === tabIndex
          ? {
              ...t,
              tabGroups: t.tabGroups.map((g, gIndex) =>
                gIndex === tableGroupIndex
                  ? {
                      ...g,
                      tablePageInfos: g.tablePageInfos.map((p) =>
                        p.identifier === tableID
                          ? {
                              ...p,
                              pageNumber: page,
                            }
                          : p,
                      ),
                    }
                  : g,
              ),
            }
          : t,
      ),
    };

    onInputUpdate(newInput);
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);

    const prevInput = buildLatestInput(currentInput, screen);
    const newInput: TablesScreenInput = {
      ...prevInput,
      search: value,
      tabs: prevInput.tabs.map((t, tIndex) =>
        tIndex === tabIndex
          ? {
              ...t,
              tabGroups: t.tabGroups.map((g, gIndex) =>
                gIndex === tableGroupIndex
                  ? {
                      ...g,
                      tablePageInfos: g.tablePageInfos.map((p) => ({
                        ...p,
                        pageNumber: 0,
                      })),
                    }
                  : g,
              ),
            }
          : t,
      ),
    };
    setInternalInput(newInput);
  };

  const currentTab = internalScreen?.tabs[tabIndex];
  const currentTableGroup = currentTab?.tableGroups[tableGroupIndex];

  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  const cutoff = currentTableGroup?.info.find((element) => element.context === CardIntent.MarketClose)?.description;
  const showCutoffProgressBar = !isSellerOrdersEnabled && isSeller && cutoff;
  const { isVillaProfile } = useWhiteLabelling();
  const isEmpty =
    currentTableGroup?.tables.every((table) => table.groups[0].rows.length === 0 && table.tableActions.length === 0) &&
    (currentTableGroup?.data?.length ?? 0) === 0;

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(getCurrentTime());
    }, ONE_MINUTE);
  }, []);

  const handleFormSubmit = () => {
    setFormDialog(defaultFormDialogState);
    onRefresh();
  };

  const progress = useMemo(() => {
    if (cutoff) {
      const cutoffParts = cutoff.split(':');
      const cutoffInSeconds = parseInt(cutoffParts[0]) * 3600 + parseInt(cutoffParts[1]) * 60;
      let adjustedTime = currentTime - cutoffInSeconds;
      if (cutoffInSeconds >= currentTime) {
        adjustedTime *= -1;
        adjustedTime = 86400 - adjustedTime; // Deduct from 24hrs to get final adjusted time
      }
      const t = (adjustedTime / 86400) * 100; // Adjusted time over 24hrs times 100 to get percentage/progress
      return t;
    }
    return 0;
  }, [cutoff, currentTime]);

  return (
    <Container sx={(theme) => ({ marginBottom: theme.spacing(10) })}>
      {enableTabs && (
        <Hero
          title={screen?.title ?? 'Hi, …'}
          cover="mountain"
          subtitle={screen?.subTitle}
          tab={tabIndex}
          onTabChange={handleTabChange}
          newTabGroups={screen?.tabs.map((tab) => tab.isNewGroup)}
          tabs={screen?.tabs.map((tab) => tab.title)}
          searchProps={
            screen?.isSearchable
              ? {
                  value: search,
                  onChange: handleSearchChange,
                  action:
                    isSellerOrdersEnabled && dateRange
                      ? {
                          variant: SearchAction.DateRange,
                          onClick: () => {
                            setShowDateRangePicker(!showDateRangePicker);
                          },
                          color: 'primary',
                          chipLabel: dateRange.label,
                          onClear: dateRange.onReset,
                        }
                      : undefined,
                }
              : undefined
          }
          isLoading={isFetching}
        />
      )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: theme.spacing(1),
          [theme.breakpoints.down('md')]: {
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          },
        })}
      >
        {currentTab ? (
          currentTab.tableGroups.length > 1 && (
            <ToggleButtonGroup
              value={tableGroupIndex}
              onChange={(_, value) => {
                if (value === null) return;
                setNumberParam(TableScreenQueryParam.Group, value);
              }}
              exclusive
              sx={(theme) => ({
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(0.5),
                [theme.breakpoints.down('md')]: {
                  minWidth: '100%',
                },
              })}
            >
              {currentTab.tableGroups.map((tableGroup, index: number) => (
                <ToggleButton
                  key={tableGroup?.title}
                  value={index}
                  className={`${index === tableGroupIndex ? 'active' : ''}`}
                  sx={(theme) => ({
                    '&.active': {
                      background: theme.palette.info.main,
                      color: theme.palette.common.white,
                      '&:hover': {
                        background: `${theme.palette.info.dark}`,
                      },
                    },
                    padding: theme.spacing(0.75, 1.25),
                    lineHeight: 1.5,
                    color: theme.palette.text.primary,
                    minWidth: isSellerOrdersEnabled ? '120px' : 'auto',
                    [theme.breakpoints.down('md')]: {
                      flex: 1,
                      minWidth: 'auto',
                    },
                  })}
                >
                  {tableGroup?.title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )
        ) : (
          <Skeleton variant="rectangular" height={32} width={240} sx={(theme) => ({ marginTop: theme.spacing(1) })} />
        )}
        <Stack direction="row" gap={1}>
          {currentTableGroup && Boolean(currentTableGroup.buttons.length) && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(1),
              })}
            >
              {currentTableGroup.buttons.map((button, i) => (
                <Button
                  key={`${tabIndex}:${tableGroupIndex}:${+i}`}
                  color={(button.color?.toLowerCase() as ButtonProps['color']) || 'primary'}
                  variant={(button.variant?.toLowerCase() as ButtonProps['variant']) || 'outlined'}
                  href={button.actions[0]?.url ? getPrefixedRoute(button.actions[0].url) : ''}
                  sx={{ textAlign: 'right' }}
                  onClick={async () => {
                    handleSchemeOrQuery(button.actions[0]?.url ?? '', '', { setFormDialog: setFormDialog });
                  }}
                >
                  {button.value}
                </Button>
              ))}
            </Box>
          )}
        </Stack>
      </Box>
      {showCutoffProgressBar && (
        <CutoffProgressBar
          progress={progress}
          barIcon={isVillaProfile ? TruckIconVilla : TruckIcon}
          label={cutoff}
          labelIcon={LabelIconVariant.Clock}
          labelToolTip={'Your cutoff time'}
        />
      )}

      {!isFetching ? (
        isEmpty ? (
          <EmptyTableState
            variant={
              isShipments
                ? currentTableGroup?.title.toLocaleLowerCase().includes('quality')
                  ? EmptyTableVariant.Quality
                  : EmptyTableVariant.Orders
                : EmptyTableVariant.Default
            }
          />
        ) : isSellerOrdersEnabled && (currentTableGroup?.data?.length ?? 0) > 0 ? (
          currentTableGroup?.data?.map((data, index, dataDisplay) => {
            if (data.__typename === 'ExpandableTable') {
              return (
                <TableWrapper
                  key={data.title}
                  table={data}
                  tables={dataDisplay as ExpandableTableType[]}
                  tabIndex={tabIndex}
                  tableGroupIndex={tableGroupIndex}
                  index={index}
                  currentInput={currentInput}
                  isRevalidating={isRevalidating}
                  onRefresh={onRefresh}
                  onTableActionClick={onTableActionClick}
                  onCustomSchemeRequest={onCustomSchemeRequest}
                  onScreenUpdate={onScreenUpdate}
                  onInputUpdate={onInputUpdate}
                  screen={screen}
                  onNewPageSelect={handleNewPageSelect}
                />
              );
            }

            const chartData = dataDisplay[0] as ChartData;
            return (
              <Box
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                  '& #chartContainer': { backgroundColor: theme.palette.common.white },
                  '& #chartSection': { marginTop: theme.spacing(4) },
                })}
                key={index}
              >
                <ChartContainer
                  title={chartData.title}
                  data={chartData}
                  charts={[chartData]}
                  toolTip={chartData.toolTip}
                  quickViewFilter={
                    <Stack direction="row" justifyContent="end">
                      {dateRange && <WeekSelector dateRange={dateRange} range={6} />}
                    </Stack>
                  }
                />
              </Box>
            );
          })
        ) : (
          currentTableGroup?.tables.map((table, index) => (
            <TableWrapper
              key={table.title}
              table={table}
              tables={currentTableGroup.tables}
              tabIndex={tabIndex}
              tableGroupIndex={tableGroupIndex}
              index={index}
              currentInput={currentInput}
              isRevalidating={isRevalidating}
              onRefresh={onRefresh}
              onTableActionClick={onTableActionClick}
              onCustomSchemeRequest={onCustomSchemeRequest}
              onScreenUpdate={onScreenUpdate}
              onInputUpdate={onInputUpdate}
              screen={screen}
              onNewPageSelect={handleNewPageSelect}
            />
          ))
        )
      ) : (
        <ExpandableTablePlaceholderNew />
      )}

      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        eTags={formDialog.eTags}
        open={formDialog.open}
        confirmDialogVariant={ConfirmDialogVariant.Default}
        onSubmit={handleFormSubmit}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
      />
      {dateRange && (
        <DateRangePickerDialog
          open={showDateRangePicker}
          startDate={dateRange.startDate ?? dayjs().subtract(2, 'months')}
          endDate={dateRange.endDate ?? dayjs()}
          onDatesChange={dateRange.onDatesChange}
          onClose={() => setShowDateRangePicker(false)}
        />
      )}
    </Container>
  );
};

export default TablesScreen;
